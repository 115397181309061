import { MetricsPanelCtrl, loadPluginCss } from 'grafana/app/plugins/sdk';

import _ from 'lodash';
import $ from 'jquery';

import appEvents from 'grafana/app/core/app_events';
import { getLocationSrv } from '@grafana/runtime';

loadPluginCss({
  dark: 'plugins/machine-list-panel/css/plugin.css',
  light: 'plugins/machine-list-panel/css/plugin-light.css',
});

class DiscretePanelCtrl extends MetricsPanelCtrl {
  static templateUrl = 'partials/module.html';
  static scrollable = true;

  defaults = {
    nameField: 'Name',
    sensorIDField: 'SensorID',
    primaryPayload: 'execution',
    payloadTypeField: 'PayloadType',
    currentTimeField: 'CurrentTime',
    sensorTypeField: 'SensorType',
    parentId: 'ParentID',
    teamField: 'Team',
    valueMaps: [{ value: 'null', op: '=', text: 'N/A' }],
    colorMaps: [{ text: 'N/A', color: '#CCC' }],
    machinesEndpoint: '/Machines/GetForCurrentUser',
    includeTimeRangeInDashLinks: false,
    dashboardPrefix: '',
    statusTagPayloadType: 'Status',
  };

  annotations: any = [];
  data: any = null;
  machineListData: any = null;
  departments: any = [];
  selectedDepartments: any = [];
  sensorTypes: any = [];
  selectedSensorTypes: any = [];
  teams: any = [];
  selectedTeams: any = [];
  selectedMachine: string = null;
  timerRunning = false;
  timer: any = null;
  lastData: any = {};
  serverTimeOffset = 0;
  modalScope: any = null;
  loadedData = false;
  modalContainer: HTMLDivElement;
  dismiss: () => void;

  /** @ngInject */
  constructor($scope, $injector, public annotationsSrv, private $interval, public templateSrv, public $compile) {
    super($scope, $injector);

    // const variable = variableSrv.variables.find(v => v.name === 'userEmail');

    // if (variable) {
    // 	const newValue = this.contextSrv.user.email;
    // 	variable.current.text = newValue;
    // 	variable.current.value = newValue;
    // 	variableSrv.variableUpdated(variable);

    // this.updateVarable('userEmail', this.contextSrv.user.email);

    // 	for (const panel of $scope.ctrl.dashboard.panels) {
    // 		for (const target of panel.targets) {
    // 			const query = target.query ? target.query : target.rawSql;
    // 			if (query && query.includes('$userEmail')) {
    // 				panel.refresh();
    // 			}
    // 		}
    // }

    // defaults configs
    _.defaultsDeep(this.panel, this.defaults);
    this.panel.display = 'timeline'; // Only supported version now

    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('render', this.onRender.bind(this));
    // this.events.on('refresh', this.onRefresh.bind(this));

    this.events.on('panel-initialized', this.onPanelInitialized.bind(this));
    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-snapshot-load', this.onDataSnapshotLoad.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));

    this.scrollToMachine = this.scrollToMachine.bind(this);
    this.fetchItemMetricsWhenDataReady = this.fetchItemMetricsWhenDataReady.bind(this);
  }

  onPanelInitialized() {
    // this.onConfigChanged();
  }

  onDataSnapshotLoad(snapshotData) {
    this.onDataReceived(snapshotData);
  }

  onDataError(err) {
    this.annotations = [];
    console.log('onDataError', err);
  }

  onInitEditMode() {
    this.addEditorTab('Machine list', 'public/plugins/machine-list-panel/partials/machine.config.html', 1);
    this.editorTabIndex = 1;
    this.refresh();
  }

  onRender() {
    if (this.data === null) {
      // || !this.context) {
      return;
    }
    this.renderingCompleted();
  }

  onDataReceived(dataList) {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.selectedMachine !== urlParams.get('var-sensor')) {
      this.selectedMachine = urlParams.get('var-sensor');
    }

    if (this.data === null && this.selectedMachine) {
      this.scrollToMachine();
    }

    let data = [];
    if (this.data === null) {
      this.data = [];
    }

    _.forEach(dataList, metric => {
      if ('table' === metric.type) {
        if (metric.refId === 'A') {
          data = this.fetchItems(metric);

          this.data = this.data.filter(i => data.some(it => it.ID === i.ID));

          _.forEach(data, item => {
            if (this.data.some(i => i.ID === item.ID)) {
              const itemIndex = this.data.findIndex(i => i.ID === item.ID);

              this.data[itemIndex].Name = item.Name;
              this.data[itemIndex].SensorID = item.SensorID;
              this.data[itemIndex].DisplayName = item.DisplayName;
              this.data[itemIndex].SensorType = item.SensorType;
              this.data[itemIndex].Team = item.Team;
              this.data[itemIndex].PayloadType = item.PayloadType;
              this.data[itemIndex].DashboardID = item.DashboardID;
              this.data[itemIndex].ID = item.ID;
              this.data[itemIndex].Selected = item.Selected;
              this.data[itemIndex].Elapsed = item.Elapsed;
              this.data[itemIndex].ParentID = item.ParentID;
            } else {
              this.data.push(item);
            }
          });

          this.loadedData = true;
        } else if (metric.refId === 'C') {
          this.fetchDepartments(metric);
        } else if (metric.refId === 'D') {
          this.fetchSensorTypes(metric);
        } else if (metric.refId === 'E') {
          this.fetchTeams(metric);
        } else {
          this.fetchItemMetricsWhenDataReady(metric);
        }
      }
    });
    this.applyFiltersFromURL();
    this.filterMachineList();
  }

  fetchItemMetricsWhenDataReady(metric) {
    if (!this.loadedData) {
      this.$timeout(() => this.fetchItemMetricsWhenDataReady(metric), 100);
    } else {
      this.fetchItemMetrics(this.data, metric);
      this.loadedData = false;
    }
  }

  scrollToMachine() {
    if (document.getElementById(this.selectedMachine)) {
      document.getElementById('machine-list').scrollTop = document.getElementById(this.selectedMachine).offsetTop - 15;
    } else {
      this.$timeout(this.scrollToMachine, 100);
    }
  }

  showMachineListFilterDialog() {
    this.modalScope = this.$scope.$new(true);

    this.modalScope.departments = this.departments;
    this.modalScope.selectedDepartments = this.selectedDepartments;
    this.modalScope.filterDepartment = department => {
      this.filterDepartment(department);
    };

    this.modalScope.sensorTypes = this.sensorTypes;
    this.modalScope.selectedSensorTypes = this.selectedSensorTypes;
    this.modalScope.filterSensorType = (type: { Name: any; Selected: boolean }) => {
      this.filterSensorType(type);
    };

    this.modalScope.teams = this.teams;
    this.modalScope.selectedTeams = this.selectedTeams;
    this.modalScope.filterTeam = (team: { Name: any; Selected: boolean }) => {
      this.filterTeam(team);
    };

    this.modalScope.dismiss = () => {
      this.modalDismiss();
    };
    this.modalScope.updateVariables = () => {
      this.updateVariables();
    };

    // this.publishAppEvent('show-modal', {
    //   src: 'public/plugins/machine-list-panel/partials/machine.filter.modal.html',
    //   scope: this.modalScope,
    //   modalClass: 'modalContainer',
    // });
    this.openDowntimeModal('public/plugins/machine-list-panel/partials/machine.filter.modal.html');
  }

  openDowntimeModal(partialPath: string) {
    const self = this;

    this.modalContainer = document.createElement('div');
    this.modalContainer.id = 'machine-list-modal-container';
    document.body.appendChild(this.modalContainer);

    $('#machine-list-modal-container').load(partialPath, () => {
      this.modalContainer.style.display = 'flex';

      $('.machine-list-modal').on('click', event => {
        event.stopPropagation();
      });

      this.modalContainer.onclick = ev => {
        this.modalDismiss();
      };

      this.$compile(this.modalContainer)(self.modalScope);
    });
  }

  modalDismiss() {
    const modalContainer = document.getElementById('machine-list-modal-container');
    modalContainer.style.display = 'none';
    modalContainer.remove();
  }

  clearMachineListFilter() {
    this.selectedDepartments = [];
    _.forEach(this.departments, department => {
      department.Selected = false;
    });

    this.selectedSensorTypes = [];
    _.forEach(this.sensorTypes, type => {
      type.Selected = false;
    });

    this.selectedTeams = [];
    _.forEach(this.selectedTeams, team => {
      team.Selected = false;
    });

    this.filterMachineList();
    this.updateVariables();
  }

  applyFiltersFromURL() {
    this.selectedDepartments = [];
    const departemntVar = this.getVariable('department');
    if (departemntVar && departemntVar.current.value !== 'All') {
      const departments = departemntVar.current.value.split('|');
      _.forEach(departments, department => {
        const v = this.departments.find((v: { Name: any }) => v.Name === department);
        if (v) {
          v.Selected = true;
          this.selectedDepartments.push(v);
        }
      });
    }

    this.selectedSensorTypes = [];
    const sensorTypeVar = this.getVariable('sensorType');
    if (sensorTypeVar && sensorTypeVar.current.value !== 'All') {
      const sensorTypes = sensorTypeVar.current.value.split('|');
      _.forEach(sensorTypes, sensorType => {
        const v = this.sensorTypes.find((v: { Name: any }) => v.Name === sensorType);
        if (v) {
          v.Selected = true;
          this.selectedSensorTypes.push(v);
        }
      });
    }

    this.selectedTeams = [];
    const teamVar = this.getVariable('team');
    if (teamVar && teamVar.current.value !== 'All') {
      const teams = teamVar.current.value.split('|');
      _.forEach(teams, team => {
        const v = this.teams.find((v: { Name: any }) => v.Name === team);
        if (v) {
          v.Selected = true;
          this.selectedTeams.push(v);
        }
      });
    }

    console.log(
      'Variables set: department=' +
        this.buildDepartmentVarValue() +
        '; sensorType=' +
        this.buildSensorTypeVarValue() +
        '; team=' +
        this.buildTeamVarValue()
    );
  }

  fetchDepartments(metric) {
    const idObj = metric.columns.find(el => el.text === 'ID');
    if (!idObj) {
      console.log('could not find department id column');
      this.departments = [];
      return;
    }
    const nameObj = metric.columns.find(el => el.text === 'Name');
    if (!nameObj) {
      console.log('could not find department name column');
      return;
    }

    const idIndex = metric.columns.indexOf(idObj);
    const nameIndex = metric.columns.indexOf(nameObj);

    _.forEach(metric.rows, row => {
      const department = {
        ID: row[idIndex],
        Name: row[nameIndex],
        Selected: false,
      };

      if (!this.departments.some(d => d.ID === department.ID)) {
        this.departments.push(department);
      }
    });
  }

  filterDepartment(department) {
    if (this.selectedDepartments.some(d => d.ID === department.ID)) {
      const itemIndex = this.selectedDepartments.findIndex(d => d.ID === department.ID);
      this.selectedDepartments.splice(itemIndex, 1);
      department.Selected = false;
    } else {
      this.selectedDepartments.push(department);
      department.Selected = true;
    }

    this.filterMachineList();
  }

  fetchSensorTypes(metric: { columns: any[]; rows: any }) {
    const idObj = metric.columns.find((el: { text: string }) => el.text === 'TypeID');
    if (!idObj) {
      console.log('could not find type id column');
      this.sensorTypes = [];
      return;
    }
    const idIndex = metric.columns.indexOf(idObj);

    _.forEach(metric.rows, (row: { [x: string]: any }) => {
      const type = {
        Name: row[idIndex],
        Selected: false,
      };
      if (!this.sensorTypes.some((d: { Name: any }) => d.Name === type.Name)) {
        this.sensorTypes.push(type);
      }
    });
  }

  filterSensorType(sensorType: { Name: any; Selected: boolean }) {
    if (this.selectedSensorTypes.some(t => t.Name === sensorType.Name)) {
      const itemIndex = this.selectedSensorTypes.findIndex(t => t.Name === sensorType.Name);
      this.selectedSensorTypes.splice(itemIndex, 1);
      sensorType.Selected = false;
    } else {
      this.selectedSensorTypes.push(sensorType);
      sensorType.Selected = true;
    }

    this.filterMachineList();
  }

  fetchTeams(metric: { columns: any[]; rows: any }) {
    const idObj = metric.columns.find((el: { text: string }) => el.text === 'Name');
    if (!idObj) {
      console.log('could not find type id column');
      this.teams = [];
      return;
    }
    const idIndex = metric.columns.indexOf(idObj);

    _.forEach(metric.rows, (row: { [x: string]: any }) => {
      const team = {
        Name: row[idIndex],
        Selected: false,
      };
      if (!this.teams.some((d: { Name: any }) => d.Name === team.Name)) {
        this.teams.push(team);
      }
    });
  }

  filterTeam(team: { Name: any; Selected: boolean }) {
    if (this.selectedTeams.some(t => t.Name === team.Name)) {
      const itemIndex = this.selectedTeams.findIndex(t => t.Name === team.Name);
      this.selectedTeams.splice(itemIndex, 1);
      team.Selected = false;
    } else {
      this.selectedTeams.push(team);
      team.Selected = true;
    }

    this.filterMachineList();
  }

  filterMachineList() {
    if (this.data) {
      this.machineListData = [];

      if (this.selectedDepartments.length === 0 && this.selectedSensorTypes.length === 0 && this.selectedTeams.length === 0) {
        this.machineListData = this.data.filter(m => m !== null).sort(this.machinesComparator);
      } else {
        this.machineListData = this.data
          .filter(
            m =>
              (this.selectedDepartments.length === 0 || this.selectedDepartments.some(i => i.ID === m.ParentID)) &&
              (this.selectedSensorTypes.length === 0 || this.selectedSensorTypes.some(i => i.Name === m.SensorType)) &&
              (this.selectedTeams.length === 0 || this.selectedTeams.some(i => i.Name === m.Team))
          )
          .sort(this.machinesComparator);

        console.log(
          'Filters applied: department=' +
            this.buildDepartmentVarValue() +
            '; sensorType=' +
            this.buildSensorTypeVarValue() +
            '; team=' +
            this.buildTeamVarValue()
        );
      }
    }
  }

  machinesComparator(one, other) {
    const onePrefix = one.SensorID[0];
    const otherPrefix = other.SensorID[0];

    if (onePrefix > otherPrefix) {
      return 1;
    }
    if (otherPrefix > onePrefix) {
      return -1;
    }

    const oneNumber = parseInt(one.SensorID.substring(1), 10);
    const otherNumber = parseInt(other.SensorID.substring(1), 10);

    if (oneNumber > otherNumber) {
      return 1;
    }
    if (oneNumber < otherNumber) {
      return -1;
    }

    return 0;
  }

  fetchItemMetrics(data, metric) {
    const sensorIDObj = metric.columns.find(el => el.text === this.panel.sensorIDField);
    const typeIndexObj = metric.columns.find(el => el.text === 'Type');
    const valueIndexObj = metric.columns.find(el => el.text === 'StringValue');
    const lastIntObj = metric.columns.find(el => el.text === 'IntValue');

    if (!sensorIDObj || !typeIndexObj || !valueIndexObj || !lastIntObj) {
      console.log(`fields were not found ${this.panel.sensorIDField}, Type, last`);
      console.log(metric);
    }

    const sensorIDIndex = metric.columns.findIndex(el => el.text === this.panel.sensorIDField);
    const typeIndex = metric.columns.findIndex(el => el.text === 'Type');
    const valueIndex = metric.columns.findIndex(el => el.text === 'StringValue');
    const lastIntIndex = metric.columns.findIndex(el => el.text === 'IntValue');
    let theStatusPayloadType = this.panel.statusTagPayloadType;
    if (theStatusPayloadType === '') {
      theStatusPayloadType = 'Status';
    }

    _.forEach(metric.rows, row => {
      const sensorID = row[sensorIDIndex];
      const itemIndex = data.findIndex(i => i.SensorID === sensorID);
      if (itemIndex === -1 || !data[itemIndex].PayloadType) {
        return;
      }

      const typeValue = row[typeIndex];
      let lastValue = row[valueIndex];

      if (lastValue === undefined || lastValue === null) {
        lastValue = row[lastIntIndex];
      }

      if (typeValue === data[itemIndex].PayloadType) {
        if (data[itemIndex].DisplayName === theStatusPayloadType) {
          data[itemIndex].Status = this.processValue(lastValue);
          data[itemIndex].StatusColor = this.getColor(lastValue);
          data[itemIndex].Time = row[0];
        } else {
          data[itemIndex].Primary = this.processValue(lastValue);
        }
      } else if (typeValue === 'AlarmCount') {
        data[itemIndex].AlarmCount = lastValue;
      }
    });

    this.calculateElapsed();
  }

  processValue(value) {
    const valueMap = this.panel.valueMaps.find(vm => vm.value === value);

    if (valueMap) {
      return valueMap.text;
    }

    return value;
  }

  fetchItems(metric) {
    const data = [];
    const parameters = this.checkParameters(metric);
    if (parameters === null) {
      return [];
    }

    const dateFieldIndex = metric.columns.indexOf(parameters.currentTimeObj);

    const nameFieldIndex = metric.columns.indexOf(parameters.nameField);
    const payloadTypeFieldIndex = metric.columns.indexOf(parameters.payloadTypeField);
    const displayNameIndex = metric.columns.indexOf(parameters.displayNameField);
    const sensorTypeIndex = metric.columns.indexOf(parameters.sensorTypeField);
    const teamIndex = metric.columns.indexOf(parameters.teamField);
    const sensorIDIndex = metric.columns.indexOf(parameters.sensorIDObj);
    const typeIDIndex = metric.columns.indexOf(parameters.typeIDObj);
    const IDIndex = metric.columns.indexOf(metric.columns.find(el => el.text === 'ID'));
    const parentIdIndex = metric.columns.indexOf(parameters.parentIdObj);

    _.forEach(metric.rows, item => {
      if (!this.serverTimeOffset) {
        this.serverTimeOffset = Date.now() - new Date(item[dateFieldIndex]).getTime();
        console.log(this.serverTimeOffset);
      }

      const sensorID = item[sensorIDIndex];
      if (sensorID) {
        const itemObj = {
          Name: item[nameFieldIndex],
          SensorID: sensorID,
          DisplayName: item[displayNameIndex],
          SensorType: item[sensorTypeIndex],
          Team: item[teamIndex],
          PayloadType: item[payloadTypeFieldIndex],
          DashboardID: item[typeIDIndex],
          ID: item[IDIndex],
          Selected: sensorID === this.selectedMachine,
          Elapsed: this.lastData[sensorID] ? this.lastData[sensorID].Elapsed : '',
          ParentID: item[parentIdIndex],
        };

        data.push(itemObj);
      }
    });

    return data;
  }

  checkParameters(metric) {
    const nameField = metric.columns.find(el => el.text === this.panel.nameField);
    if (!nameField) {
      console.log('could not find name column');
      return null;
    }

    const payloadTypeField = metric.columns.find(el => el.text === this.panel.payloadTypeField);
    if (!payloadTypeField) {
      console.log('could not find payload type column');
      return null;
    }

    const displayNameField = metric.columns.find(el => el.text === 'DisplayName');
    if (!displayNameField) {
      console.log('could not find display name column');
      return null;
    }

    const sensorIDObj = metric.columns.find(el => el.text === this.panel.sensorIDField);
    if (!sensorIDObj) {
      console.log('could not find sensor id column');
      return null;
    }

    const typeIDObj = metric.columns.find(el => el.text === this.panel.typeIDField);
    if (!typeIDObj) {
      console.log('could not find type id column');
      return null;
    }

    const currentTimeObj = metric.columns.find(el => el.text === this.panel.currentTimeField);
    if (!currentTimeObj) {
      console.log('could not find type id column');
      return null;
    }

    const parentIdObj = metric.columns.find(el => el.text === this.panel.parentId);
    if (!parentIdObj) {
      console.log('could not find type parent id');
      return null;
    }

    const sensorTypeField = metric.columns.find(el => el.text === this.panel.sensorTypeField);
    if (!sensorTypeField) {
      console.log('could not find sensorType column');
      return null;
    }

    const teamField = metric.columns.find(el => el.text === this.panel.teamField);
    if (!teamField) {
      console.log('could not find team column');
      return null;
    }

    return {
      nameField: nameField,
      payloadTypeField: payloadTypeField,
      displayNameField: displayNameField,
      sensorTypeField: sensorTypeField,
      teamField: teamField,
      sensorIDObj: sensorIDObj,
      typeIDObj: typeIDObj,
      currentTimeObj: currentTimeObj,
      parentIdObj: parentIdObj,
    };
  }

  select(item) {
    const department = encodeURIComponent(this.buildDepartmentVarValue());
    const sensorType = encodeURIComponent(this.buildSensorTypeVarValue());
    const team = encodeURIComponent(this.buildTeamVarValue());
    const sensor = encodeURIComponent(item.SensorID);

    let query = `?var-sensor=${sensor}&var-department=${department}&var-sensorType=${sensorType}&var-team=${team}`;
    if (this.panel.includeTimeRangeInDashLinks) {
      const fromEncStr = encodeURIComponent(this.range.raw.from.toString());
      const toEncStr = encodeURIComponent(this.range.raw.to.toString());
      query += `&from=${fromEncStr}&to=${toEncStr}`;
    }

    const url = `${window.location.origin}/d/${this.panel.dashboardPrefix}${item.DashboardID}${query}`;

    console.log('Redirecting to ' + url);
    window.location.href = url;
  }

  calculateElapsed() {
    const now = Date.now();

    _.forEach(this.data, item => {
      if (item.Time && (!this.lastData[item.SensorID] || this.lastData[item.SensorID].Time !== item.Time)) {
        this.lastData[item.SensorID] = {
          Time: item.Time,
        };

        const totalSeconds = (now - this.serverTimeOffset - item.Time) / 1000;

        const processedTime = this.processTime(totalSeconds);

        item.Elapsed = this.calculateElapsedString(processedTime);
        this.lastData[item.SensorID].Elapsed = item.Elapsed;
      }
    });

    this.startTimer();
  }

  startTimer() {
    if (this.timerRunning) {
      return;
    }

    this.timerRunning = true;

    this.timer = this.$interval(() => {
      if (!this.timerRunning) {
        clearTimeout(this.timer);
      }

      _.forEach(this.data, item => {
        if (this.lastData[item.SensorID] && this.lastData[item.SensorID].Elapsed) {
          const now = Date.now();

          const totalSeconds = (now - this.serverTimeOffset - item.Time) / 1000;
          if (!item.Time) {
            return;
          }

          const newTime = this.processTime(totalSeconds);

          item.Elapsed = this.calculateElapsedString(newTime);
          this.lastData[item.SensorID].Elapsed = item.Elapsed;
        }
      });
    }, 1000);
  }

  calculateElapsedString(newTime) {
    const days = newTime.days;
    const hours = newTime.hours;
    const minutes = newTime.minutes;
    const seconds = newTime.seconds;

    let postfix = '';
    let elapsed = '';
    if (days > 0) {
      elapsed = `${days}d `;
    }
    if (hours > 0) {
      elapsed += `${hours}:${Math.log10(minutes) + 1 < 2 ? '0' + minutes : minutes}:`;
      postfix = ' h';
    } else {
      elapsed += `${minutes}:`;
      postfix = 'min';
    }

    elapsed += `${Math.log10(seconds) + 1 < 2 ? '0' + seconds : seconds} ${postfix}`;
    return elapsed;
  }

  processTime(totalSeconds) {
    const days = Math.floor(totalSeconds / 86400);
    totalSeconds -= days * 86400;

    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds -= hours * 3600;

    const minutes = Math.floor(totalSeconds / 60);
    totalSeconds -= minutes * 60;

    const seconds = Math.round(totalSeconds);

    return { days: days, hours: hours, minutes: minutes, seconds: seconds };
  }

  getColor(value) {
    const colorMap = this.panel.colorMaps.find(c => c.text === value);
    if (colorMap === undefined) {
      return '#FFFFFF';
    }

    return colorMap.color + ' !important';
  }

  addColorMap() {
    this.panel.colorMaps.push({ text: '???', color: this.randomColor() });
  }

  removeColorMap(map) {
    const index = _.indexOf(this.panel.colorMaps, map);
    this.panel.colorMaps.splice(index, 1);
  }

  randomColor() {
    const letters = 'ABCDE'.split('');
    let color = '#';
    for (let i = 0; i < 3; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  removeValueMap(map) {
    const index = _.indexOf(this.panel.valueMaps, map);
    this.panel.valueMaps.splice(index, 1);
  }

  addValueMap() {
    this.panel.valueMaps.push({ value: '', op: '=', text: '' });
  }

  clear() {
    appEvents.emit('graph-hover-clear');
    this.render();
  }

  buildDepartmentVarValue() {
    if (this.selectedDepartments.length > 0) {
      return this.selectedDepartments.map(i => i.Name).join('|');
    }
    return 'All';
  }
  buildSensorTypeVarValue() {
    if (this.selectedSensorTypes.length > 0) {
      return this.selectedSensorTypes.map(i => i.Name).join('|');
    }
    return 'All';
  }
  buildTeamVarValue() {
    if (this.selectedTeams.length > 0) {
      return this.selectedTeams.map(i => i.Name).join('|');
    }
    return 'All';
  }

  updateVariables() {
    this.updateVarable('department', this.buildDepartmentVarValue());
    this.updateVarable('sensorType', this.buildSensorTypeVarValue());
    this.updateVarable('team', this.buildTeamVarValue());

    console.log(
      'Variables updated: department=' +
        this.buildDepartmentVarValue() +
        '; sensorType=' +
        this.buildSensorTypeVarValue() +
        '; team=' +
        this.buildTeamVarValue()
    );
  }

  updateVarable(varname: string, value: string) {
    const name = 'var-' + varname;
    getLocationSrv().update({
      partial: true,
      query: {
        [name]: value,
      },
      replace: true,
    });
    // const v = this.getVariable(varname);
    // if (v) {
    // 	this.variableSrv.setOptionAsCurrent(v, {
    // 		text: value,
    // 		value: value,
    // 	});
    // 	this.variableSrv.variableUpdated(v, true);
    // } else {
    // 	console.log(`variable ${varname} not found`);
    // }
  }

  getVariable(varname: string): any {
    const v = _.find(this.templateSrv.getVariables(), (check: { name: string }) => {
      return check.name === varname;
    });
    return v;
  }
}

export { DiscretePanelCtrl as PanelCtrl };
